/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import cx from 'classnames';
import Layout from '../../components/Layout';
import Button from '../../components/ButtonPrimary';
import Pdf from '../../assets/resume_Sumit_Pal_2022.pdf';
import {
  overview,
  experience,
  card,
  downloadButton,
} from './about.module.scss';

const site = 'sumitpal.in';
const title = 'About Me | Sumit Pal';
const description = 'Sumit`s professional experience and Resume';
const seo = {
  title,
  description,
  canonical: site,
  openGraph: {
    url: site,
    title,
    description,
    site_name: site,
  },
};

function About() {
  return (
    <Layout seoData={seo}>
      <div className="">
        <section className={overview}>
          <div className="flex flex-column container">
            <h1 className="weigthed-text text-brand-underline-middle">
              About Sumit
            </h1>
            <p className="para-style-grey">
              I am a Frontend Technologies specialist based in Bengaluru, India.
              During the 2020 pandemic, I had a chance to return to my hometown,
              reliving the simplicity of days gone by. This immersive experience
              amidst nature inspired fresh perspectives in problem-solving. Now,
              I find myself in the heart of India's tech hub, the Silicon Valley
              - Bangalore.
            </p>
            <p className="para-style-grey">
              My passion lies in crafting intuitive web applications, seamlessly
              blending design artistry with programming expertise, enhancing our
              daily experiences.
            </p>
            <p className="para-style-grey">
              Beyond coding, I enjoy creating content to aid others, writing
              blogs, learning voraciously, and sharing insights with you all.
              Paintings, Photography and cinematography are cherished hobbies
              that allow me to capture life's most precious moments.
            </p>
          </div>
        </section>
        <section className={experience}>
          <div className="flex flex-column container">
            <h2 className="weigthed-text text-brand-underline-middle header-h2">
              Professional Experience
            </h2>
            <div className={card}>
              <div className="flex flex-space-between">
                <h3 className="header-h3 m-b-0">Tekion Corp.</h3>
                <span className="header-h5 color-defused">
                  SSE | Aug 2020 - Present
                </span>
              </div>
              <p className="header-h5 m-t-12 p-b-12">
                The First & Fastest Cloud-Native Automotive Retail Platform.
              </p>
              <ul>
                <li className="para">
                  <b>Rule Engine</b> - Designed an intuitive application
                  empowering users to craft intricate rules, seamlessly merging
                  various actions and conditions. These rules can be triggered
                  manually or automatically as needed. <br />I spearheaded full
                  frontend ownership, architecting the frontend with React,
                  Redux, D3, and bespoke UI interaction logic. Additionally, I
                  led frontend architecture, orchestrated new feature
                  development, collaborated on design, managed teams, and
                  integrated the Rule Engine seamlessly with other departments
                  like CRM, Deals, Service, Dev Platform, and Chat bot for
                  maximum efficiency and synergy. This comprehensive approach
                  ensured a holistic and optimized system.
                </li>
                <img
                  style={{ width: '100%' }}
                  src="https://i.imgur.com/95arw8L.png"
                ></img>
              </ul>
            </div>
            <div className={cx(card, 'm-t-36')}>
              <div className="flex flex-space-between">
                <h3 className="header-h3 m-b-0">FusionCharts</h3>
                <span className="header-h5 color-defused">
                  Associate Team Lead - SE | July 2016 - Aug 2020
                </span>
              </div>
              <p className="header-h5 m-t-12 p-b-12">
                FusionCharts provides JavaScript charting library for web and
                enterprise applications across globe.
              </p>
              <ul>
                <li className="para">
                  <b>Web Apps</b> - Frontend architecture and migration of
                  FusionCharts web apps from PHP to NodeJs with VueJs SSR.
                  Achieved high performance, improved seoData, accessibility,
                  and browser compatibility.
                </li>
                <li className="para">
                  <b>Design System</b> - Build a custom design system for
                  FusionCharts from scratch to create a consistent experience
                  across different apps, which allowed developers and designers
                  to reuse design components.
                </li>
                <li className="para">
                  <b>Visual Regression</b> - A visual comparison test to compare
                  design changes between new and old pages, to deliver seamless
                  and pixel- perfect deployments.
                </li>
                <li className="para">
                  <b>Fusion Store</b> - Contributed and maintained to this
                  internal tool like jsFiddle where one can create and store
                  charts.
                </li>
              </ul>
            </div>

            <a href={Pdf} rel="noopener noreferrer" target="_blank">
              <Button customClassNames={downloadButton}>My Resume</Button>
            </a>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default About;
